


































































































































































































































































































































































































































































































.group_form {
  display: flex;
}
